import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import toast from 'react-hot-toast';
import Select from "react-select"
import { ClientService } from '../../component/services/client.service';
import CsrReportTable from '../../component/table/CsrReportTable';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import TablePaginationComponent from '../../component/table/TablePagination';
import moment from 'moment';
import DownloadCsv from '../../component/DownloadCsv';
import { VerificationProcessEnum } from '../../component/enum/VerficationProcess.enum';

const Csr = () => {


    const [selectValue, setSelectValue] = useState<any>();
    const [shifts, setShifts] = useState<any>();
    const [verificationData, setVerificationData] = useState<any>();
    const [searchText, setSearchText] = useState<string>('');
    const [showUserModal, setShowUserModal] = useState<boolean>(false)

    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>()


    const [loading, setLoading] = useState<boolean>(false);

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<any>(10);
    const [totalCount, setTotalCount] = useState<number>(0);

    const [centers, setCenters] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();

    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        let params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        selectedStates.forEach((state: any) => params.append("state[]", state?.value));
        selectedDistricts.forEach((district: any) => params.append("district[]", district?.value));

        await ClientService.getCenters(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setCenters(res.data?.centers?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getUniqueState = async () => {
        const params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        await ClientService.getStates(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setStates(res.data.states?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        let params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        selectedStates.forEach((state: any) => params.append("state[]", state?.value));
        await ClientService.getCities(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setDistricts(res.data?.districts?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName + " - " + moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getVerificationData = async () => {
        setLoading(true)
        // const payload = {
        //     state: selectedStates?.map((data: any) => data?.value),
        //     city: selectedDistricts?.map((data: any) => data?.value),
        //     zone: selectedZones?.map((data: any) => data?.value),
        //     centerId: selectedCenters?.map((data: any) => data?.value),
        // }
        let payload = {};

        if (selectedStates?.length > 0) {
            payload = { ...payload, state: selectedStates?.map((data: any) => data?.value) }
        }
        if (selectedDistricts?.length > 0) {
            payload = { ...payload, city: selectedDistricts?.map((data: any) => data?.value) }
        }
        if (selectedZones?.length > 0) {
            payload = { ...payload, zone: selectedZones?.map((data: any) => data?.value) }
        }
        if (selectedCenters?.length > 0) {
            payload = { ...payload, centerId: selectedCenters?.map((data: any) => data?.value) }
        }
        await ClientService.getVerificationData(selectValue, pageNumber, pageSize, payload)
            .then((res) => {
                if (res.status === 200) {
                    setVerificationData(res.data?.data?.verification);
                    setTotalCount(res.data?.data?.total_count)
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
            })
            .finally(() => setLoading(false))
    };

    const finger_name = [
        { "label": "Thumb", "value": "thumb" },
        { "label": "Index Finger", "value": "index_finger" },
        { "label": "Middle Finger", "value": "middle_finger" },
        { "label": "Ring Finger", "value": "ring_finger" },
        { "label": "Little Finger", "value": "little_finger" }
    ]

    function createCSVData() {
        try {
            const csvData = verificationData.map((data: any) => {
                let f1 = data?.verificationDetails?.find((detail: any) => detail?.method === 'fingerprint');
                let f2 = data?.verificationDetails?.find((detail: any) => detail?.method === 'fingerprint2');
                const [name, code] = data?.['Biometric Operator Name|Id']?.split(" | ").map((s: any) => s.trim());

                return {
                    "Candidate Roll No.": data?.['Roll No.'],
                    "Candidate Name": data?.['S. Name'],
                    "Biometric Operatic ID": code,
                    "Biometric Operator Name": name,
                    "Division": data?.['Zone'],
                    "State": data?.['State'],
                    "City": data?.['City'],
                    "Venue ID": data?.['Center'],
                    "Venue Name": data?.['Center Name'],
                    "Live Image": data?.['Live Photo'] ? 'Captured' : 'Not Captured',
                    "Facial": data?.verificationDetails?.find((detail: any) => detail?.method === VerificationProcessEnum.Facial)?.value,
                    "Right Fingerprint": f1?.value
                        ? f1.value + (f2?.value !== "Exception"
                            ? " - " + (f1?.remarks
                                ? finger_name?.find((data: any) => data?.value === JSON.parse(f1.remarks)?.fingersData)?.label || "Thumb"
                                : "Thumb")
                            : "")
                        : "",

                    "Left Fingerprint": f2?.value
                        ? f2.value + (f2.value !== "Exception"
                            ? " - " + (f2?.remarks
                                ? finger_name?.find((data: any) => data?.value === JSON.parse(f2.remarks)?.fingersData)?.label || "Thumb"
                                : "Thumb")
                            : "")
                        : ""
                }
            });
            return csvData;
        }
        catch (e) {
            console.log(e);
            return [];
        }
    }


    useEffect(() => {
        if (selectValue) {
            getAllZones();
        }
    }, [selectValue]);

    useEffect(() => {
        if (selectedZones?.length > 0) {
            getUniqueState();
        } else {
            setStates([]);
            setSelectedStates([]);
        }
    }, [selectedZones]);

    useEffect(() => {
        if (selectedStates?.length > 0 && selectedZones) {
            getUniqueCity();
        } else {
            setDistricts([]);
            setSelectedDistricts([]);
        }
    }, [selectedStates]);

    useEffect(() => {
        if (selectedStates && selectedZones && selectedDistricts?.length > 0) {
            getUniqueCenter();
        } else {
            setCenters([]);
            setSelectedCenters([]);
        }
    }, [selectedDistricts]);

    useEffect(() => {
        if (selectValue) {
            setVerificationData([])
            getVerificationData();
        }
    }, [selectValue, pageNumber, pageSize, selectedCenters, selectedDistricts, selectedStates, selectedZones]);



    useEffect(() => {
        getAllShift();
    }, [])

    return (
        <>
            <Row className="mt-5 text-left">
                <Col md={8}>
                    <h4 className="text-secondary mt-3 fw-bold">Center Report</h4>
                </Col>
                <Col md={4}>
                    <Form.Label className="text-muted fw-bold">
                        Select Shift
                    </Form.Label>
                    <Select
                        options={shifts}
                        placeholder="Select Shifts"
                        onChange={(e: any) => setSelectValue(e?.value)}
                        isClearable
                        value={selectValue ? shifts.find((shift: any) => shift.value === selectValue.value) : null}
                    />
                </Col>
            </Row>

            {
                verificationData?.length > 0 &&
                <DownloadCsv
                    headers={["Candidate Roll No.", "Candidate Name", "Biometric Operatic ID", "Biometric Operator Name", "Division", "State", "City", "Venue ID", "Venue Name", "Live Image", "Facial", "Right Fingerprint", "Left Fingerprint"]}
                    createCsv={() => { return createCSVData() }}
                    fileName='center-report'
                />
            }


            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Divisions
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(zones?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedZones(
                                                zones?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedZones(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedZones}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    States
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(states?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedStates(
                                                states?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedStates(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedStates}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />

                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    City
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(districts?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedDistricts(
                                                districts?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedDistricts(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedDistricts}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Centers
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(centers?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedCenters(
                                                centers?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedCenters(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedCenters}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Row>
                <Col>
                    <CsrReportTable data={verificationData} pageNumber={pageNumber} pageSize={pageSize} loading={loading} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TablePaginationComponent
                        currentPage={pageNumber}
                        itemsCount={totalCount}
                        itemsPerPage={pageSize}
                        setItemsPerPage={(e: any) => setPageSize(e)}
                        setCurrentPage={setPageNumber}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Csr