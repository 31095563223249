import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { ClientService } from '../../component/services/client.service';
import moment from 'moment';
import Select from 'react-select';

export const ShiftReport = () => {
    const [selectedValue, setSelectedValue] = useState<any>()
    const [shifts, setShifts] = useState<any>();
    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName + " - " + moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id,
                        startTime: data.startTime,
                        endTime: data.endTime
                    }
                }))
                // setShiftDate(res.data.shifts.map((data: any) => {
                //     return {
                //         label: moment(data.startTime).format("DD MMM YYYY"),
                //         value: data._id
                //     }
                // }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    useEffect(() => {
        getAllShift();
    }, []);
    return (
        <>
            <Row className="mt-5 text-left">
                <Col md={8}>
                    <h4 className="text-secondary mt-3 fw-bold">Download Shift Report</h4>
                </Col>
                <Col md={4}>
                    <Form.Label className="text-muted fw-bold">
                        Select Shift
                    </Form.Label>
                    <Select
                        options={shifts}
                        // onChange={(e: any) => setSelectedShiftDate(e)} // Store full object
                        placeholder="Select Shifts"
                        onChange={(e: any) => setSelectedValue(e?.value)}
                        isClearable
                        value={selectedValue ? shifts.find((shift: any) => shift.value === selectedValue.value) : null}
                    />
                </Col>
            </Row>
            {
                selectedValue &&
                <div className="mt-5 d-flex justify-content-between align-items-center">
                    <Button size="sm" variant='dark' disabled={true}>
                        Download
                    </Button>
                </div>
            }
        </>
    )
}
