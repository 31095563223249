import React from 'react'
import { Button, Image, Modal } from 'react-bootstrap';

interface IPreviewModal {
    show: any,
    handleClose: any
}

export default function BypassPreviewModal(props: IPreviewModal) {
    return (
        <Modal
            show={props.show ? true : false}
            onHide={props.handleClose}
            size="lg"
            animation={true}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Bypass Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="d-flex align-items-center justify-content-center mb-1 flex-wrap gap-5">
                        {props?.show && props?.show.length > 0 ? (
                            props?.show.map((data: any, index: number) => (
                                <div key={index} className="d-flex flex-row align-items-center" style={{ gap: 10 }}>
                                    {/* First Image */}
                                    <div className="d-flex flex-column align-items-center" style={{ width: 200, height: 200 }}>
                                        {data?.bypassImage ? (
                                            <>
                                                <Image
                                                    src={data.bypassImage}
                                                    width={"100%"} height={"100%"}
                                                    style={{ cursor: "pointer", marginBottom: 5, objectFit: 'contain' }}
                                                />
                                                <span className="text-capitalize" style={{ fontSize: 10 }}>
                                                    {data?.method === "fingerprint" ? "Right Fingerprint" :
                                                        data?.method === "fingerprint2" ? "Left Fingerprint" : data?.method}
                                                </span>
                                            </>
                                        ) : (
                                            <span style={{ fontSize: 10 }}>No Image</span>
                                        )}
                                    </div>

                                    {/* Second Image */}
                                    <div className="d-flex flex-column align-items-center" style={{ width: 200, height: 200 }}>
                                        {data?.bypassImage2 ? (
                                            <>
                                                <Image
                                                    src={data.bypassImage2}
                                                    width={"100%"} height={"100%"}
                                                    style={{ cursor: "pointer", marginBottom: 5, objectFit: 'contain' }}
                                                />
                                                <span className="text-capitalize" style={{ fontSize: 10 }}>
                                                    {data?.method === "fingerprint" ? "Right Fingerprint" :
                                                        data?.method === "fingerprint2" ? "Left Fingerprint" : data?.method}
                                                </span>
                                            </>
                                        ) : (
                                            <span style={{ fontSize: 10 }}>No Image</span>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <span>No Image</span>
                        )}
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    )
}
