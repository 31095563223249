import React from 'react'
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';

interface IPreviewModal {
    data: any,
    handleClose: any
}

export default function ManualVerificationModal({ data, handleClose }: IPreviewModal) {
    return (
        <Modal
            show={data?.manuallyVerified}
            onHide={handleClose}
            size="lg"
            animation={true}
            // backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Manual Verification Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Text title='Method' value={data?.method === "fingerprint" ? "Right Fingerprint" : data?.method === "fingerprint2" ? "Left Fingerprint" : data?.method} />
                        <Text title='Bypass Type' value={data?.bypassType} />
                        <Text title='Bypass SubType' value={data?.bypassSubType} />
                        <Text title='Bypass Reason' value={data?.bypassReason} />
                    </Col>
                </Row>
                <Row>
                    {
                        data?.bypassImage2 &&
                        <>
                            <p>Bypass Image:</p>
                            <Image src={data?.bypassImage2} className='img-fluid' />
                        </>
                    }
                    {
                        data?.bypassImage &&
                        <>
                            <p>Bypass Image:</p>
                            <Image src={data?.bypassImage} className='img-fluid' />
                        </>
                    }
                </Row>
            </Modal.Body>
        </Modal >
    )
}


function Text({ title, value }: {
    title: string,
    value: any
}) {
    return (
        <div className='mt-2'>
            <span className='fw-bold'>{title}: </span>
            <span>{value}</span>
        </div>
    )
}