import React, { useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

interface PaginationComponentProps {
  itemsCount: number;
  itemsPerPage: number;
  setItemsPerPage: (page: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  alwaysShown?: boolean;
}

const TablePaginationComponent: React.FC<PaginationComponentProps> = ({
  itemsCount = 0,
  itemsPerPage = 10,
  currentPage = 1,
  setCurrentPage,
  alwaysShown = true,
  setItemsPerPage
}) => {
  const pagesCount = Math.ceil(itemsCount / itemsPerPage);
  const isPaginationShown = alwaysShown || pagesCount > 1;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  const changePage = (number: number) => {
    if (currentPage === number) return;
    setCurrentPage(number);
  };

  const onPageNumberClick = (pageNumber: number) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    if (!isCurrentPageFirst) {
      changePage(currentPage - 1);
    }
  };

  const onNextPageClick = () => {
    if (!isCurrentPageLast) {
      changePage(currentPage + 1);
    }
  };

  const setLastPageAsCurrent = () => {
    if (currentPage > pagesCount) {
      setCurrentPage(pagesCount || 1);
    }
  };

  let isPageNumberOutOfRange = false;

  const pageNumbers = Array.from({ length: pagesCount }, (_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers =
      Math.abs(pageNumber - currentPage) <= 2;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}
          style={{ zIndex: 0 }}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  useEffect(setLastPageAsCurrent, [pagesCount, currentPage, setCurrentPage]);

  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          {isPaginationShown && (
            <Pagination className="my-0">
              <Pagination.Prev
                onClick={onPreviousPageClick}
                disabled={isCurrentPageFirst}
              />
              {pageNumbers}
              <Pagination.Next
                onClick={onNextPageClick}
                disabled={isCurrentPageLast}
              />
            </Pagination>
          )}
          <div className="my-3 mt-md-0">
            <div className='row'>
              <div className="col pe-md-0">
                <span className="small fw-normal flex-grow-1">Records Per Page</span>
              </div>
              <div className="col-auto">
                <select
                  className="form-select bg-light form-select-sm"
                  aria-label="Select number of items per page"
                  onChange={(e) => {
                    setCurrentPage(1);
                    setItemsPerPage(parseInt(e.target.value));
                  }}
                  value={itemsPerPage}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TablePaginationComponent;
