import { faBullseye, faFilter, faSearch, faSquarePollVertical } from "@fortawesome/free-solid-svg-icons";
import CsrIndex from "../../View/Csr/Csr.index";
import FilterIndex from "../../View/Filter/Filter.index";
import IRouter, { IGroupedRouter } from "../Interface/IRouter";
import SearchIndex from "../../View/Search/Search.index";
import CenterCount from "../../View/CenterCount/CenterCount.index";
import CenterStatsDetails from "../../View/CenterStatsDetails/CenterStatsDetails";
import CSRReport from "../../View/CSRReport/CSRReport";
import BypassDetails from "../../View/Bypass/Bypass";
import CsrStatsIndex from "../../View/Csr/CsrStats.index";
import InvigilatorsIndex from "../../View/Invigilators/Invigilators.index";
import InvigilatorStatsIndex from "../../View/InvigilatorStats/InvigilatorStatsIndex";
import VerifiedStats from "../../View/Csr/VerifiedStats";
import Dashboard from "../../View/Dashboard/Dashboard";
import CityWiseHandeling from "../../View/CityWiseHandeling";
import { ShiftReport } from "../../View/ShiftReport/ShiftReport";

const reportRoutes: IRouter[] = [
    {
        path: "center-report",
        navbarShow: true,
        element: CsrIndex,
        name: "Center Report",
        icon: faSquarePollVertical
    },
    {
        path: "center-data",
        navbarShow: true,
        element: FilterIndex,
        name: "Center Data",
        icon: faFilter
    },
    {
        path: "csr-report",
        navbarShow: true,
        element: CSRReport,
        name: "CSR Report",
        icon: faSearch
    },
    {
        path: "bypass",
        navbarShow: true,
        element: BypassDetails,
        name: "Bypass Candidates",
        icon: faSearch
    },

];

const statsRoutes: IRouter[] = [
    // {
    //     path: "csr-stats",
    //     navbarShow: true,
    //     element: CsrStatsIndex,
    //     name: "CSR Stats",
    //     icon: faSearch
    // },
    {
        path: "center-stats",
        navbarShow: true,
        element: CenterCount,
        name: "Center Stats",
        icon: faSearch
    },
    {
        path: "center-stats/:shiftId/:centerId",
        navbarShow: false,
        element: CenterStatsDetails,
        name: "Center Stats",
        icon: faSearch
    },
    {
        path: "invigilator-stats",
        navbarShow: true,
        element: InvigilatorStatsIndex,
        name: "Biometric Operator Stats",
        icon: faSearch
    },
    {
        path: "invigilators",
        navbarShow: true,
        element: InvigilatorsIndex,
        name: "Biometric Operator",
        icon: faSearch
    },
    {
        path: "search",
        navbarShow: true,
        element: SearchIndex,
        name: "Search Student",
        icon: faSearch
    },
    {
        path: "average-handling",
        navbarShow: true,
        element: CityWiseHandeling,
        name: "City Wise Average Handling",
        icon: faSearch
    },
    {
        path: "download-shift-report",
        navbarShow: true,
        element: ShiftReport,
        name: "Shift Report",
        icon: faSearch
    }
];

const settingsRoutes: IRouter[] = [
    {
        path: "bypass",
        navbarShow: true,
        element: BypassDetails,
        name: "Bypass Candidates",
        icon: faSearch
    },
    {
        path: "search",
        navbarShow: true,
        element: SearchIndex,
        name: "Search Student",
        icon: faSearch
    }
]

const dashRoutes: IRouter[] = [
    {
        path: "dashboard",
        navbarShow: true,
        element: Dashboard,
        name: "Dashboard",
        icon: faSearch
    },
]

export const GroupedRoutes: IGroupedRouter[] = [
    {
        path: "/reports",
        navbarShow: true,
        name: "Exam Admission Reports",
        icon: faBullseye,
        children: [...reportRoutes]
    },
    {
        path: "/stats",
        navbarShow: true,
        name: "Individual Reports",
        icon: faBullseye,
        children: [...statsRoutes]
    },
    // {
    //     path: "/settings",
    //     navbarShow: true,
    //     name: "Settings",
    //     icon: faBullseye,
    //     children: [...settingsRoutes]
    // }
];

const mainRoutes: IRouter[] = [...reportRoutes, ...statsRoutes, ...settingsRoutes, ...dashRoutes];
export default mainRoutes;