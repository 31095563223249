import React from 'react';
import { Badge, Card, Image, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import SplitPreviewModal from '../modals/SplitPreview.modal';
import { FaCircleExclamation } from "react-icons/fa6";
interface ICsrReportTable {
  data: any[];
  pageNumber: number;
  pageSize: number;
  loading?: boolean;
}

const CsrReportTable = ({ data, pageNumber, pageSize, loading = false }: ICsrReportTable) => {


  const [splitPreview, setSplitPreview] = React.useState<any>(null);

  console.log("splitPreview", splitPreview);

  function getHeaders() {
    if (!data || !data.length) return [];

    let keys = new Set<string>();

    Object.entries(data[0]).forEach(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number') {
        keys.add(key);
      } else if (key === 'verificationDetails' && Array.isArray(value)) {
        value.forEach((verification: any, idx: number) => {
          if (verification.method) {
            keys.add(`Verification (${verification.method === "fingerprint" ? "Right Fingerprint" : verification.method === "fingerprint2" ? "Left Fingerprint" : verification.method})`);
          }
        });
      }
    });
    return Array.from(keys);
  }


  const finger_name = [
    { "label": "Thumb", "value": "thumb" },
    { "label": "Index Finger", "value": "index_finger" },
    { "label": "Middle Finger", "value": "middle_finger" },
    { "label": "Ring Finger", "value": "ring_finger" },
    { "label": "Little Finger", "value": "little_finger" }
  ]

  const hand_data = [
    { "label": "Left Hand", "value": "fingerprint2" },
    { "label": "Right Hand", "value": "fingerprint" }
  ]

  return (
    <>
      <Card
        className="mt-3"
        style={{
          backgroundColor: "#f9f9f9",
          borderRadius: "12px",
          border: "none",
        }}
      >
        <Card.Body>
          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                {loading ? (
                  <></>
                ) : (
                  getHeaders().map((header, index) => (
                    <th style={{ fontSize: 14 }} key={index}>{header && header == "Zone" ? "Division" : header === "Live Photo" ? "Live Image" : header === "Center" ? "Venue Id" : header === "Center Name" ? "Venue Name" : header}</th>
                  ))
                )}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={getHeaders().length}>
                    <span>Loading data... <Spinner animation="border" size="sm" /></span>
                  </td>
                </tr>
              ) : data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={item?._id || index}>
                    {getHeaders().map((header, idx) => {
                      if (header === "Live Photo" || header === "Photo") {
                        const photoUrl = item[header];
                        return (
                          <td key={idx}>
                            <span className="fs-12 cursor-pointer">
                              <Image src={photoUrl} height="40" width="40" onClick={() => setSplitPreview({ livePhoto: item["Live Photo"], uploadPhoto: item["Photo"] })} />
                            </span>
                          </td>
                        );
                      }

                      else if (header.startsWith("Verification (") && Array.isArray(item.verificationDetails)) {
                        let method = header.replace("Verification (", "").replace(")", "");
                        if (method === "Right Fingerprint") method = "fingerprint";
                        if (method === "Left Fingerprint") method = "fingerprint2";
                        const verification = item.verificationDetails.find((v: any) => v.method === method);
                        return (
                          <td key={idx} className="fs-italic fs-12">
                            {verification ? verification.value : "N/A"}
                            {verification && verification.remarks &&
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    <span>
                                      {/* Hand: {hand_data?.find((data:any) => data?.value === JSON.parse(verification?.remarks)?.handsData)?.label}<br/> */}
                                      Finger: {finger_name?.find((data: any) => data?.value === JSON.parse(verification?.remarks)?.fingersData)?.label || "thumb"}<br />
                                      Remarks: {JSON.parse(verification?.remarks)?.remaks || "N/A"}<br />
                                    </span>
                                  </Tooltip>
                                }
                              >
                                <span>
                                  <FaCircleExclamation className='text-success ms-2' style={{ cursor: "pointer" }} size={14} />
                                </span>
                              </OverlayTrigger>

                            }
                          </td>
                        );
                      }

                      else {
                        return (
                          <td key={idx} className="fs-italic fs-12">
                            {item[header] || "N/A"}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={getHeaders().length} className="text-center">
                    <span className="fs-12 fw-bold">No item found</span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <SplitPreviewModal
        show={splitPreview}
        handleClose={() => setSplitPreview(null)}
      />
    </>
  );
};

export default CsrReportTable;
