import React from 'react'
import { Image, Spinner } from 'react-bootstrap'
import Logo from "../../assests/Logo1.png";

export default function CetLoader() {
    return (
        <>
            <div>
                <div className="d-flex justify-content-center align-items-center my-3 gap-2">
                    <Image
                        src={Logo}
                        width={50}
                    />
                    <p className='fs-4 p-0 m-0'>Fetching Data wait
                    </p>
                    <Spinner size='sm' animation="border" />
                </div>
            </div>
        </>
    )
}
