import React from "react";
import { Table, Spinner, Container, Card } from "react-bootstrap";
import moment from "moment";

interface Props {
    data: any;
    loading: boolean;
}

const DataTable: React.FC<Props> = ({ data, loading }) => {
    return (
        <>

            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th style={{ fontSize: 14 }}>#</th>
                                <th style={{ fontSize: 14 }}>Center</th>
                                <th style={{ fontSize: 14 }}>User Name</th>
                                <th style={{ fontSize: 14 }}>Operator ID</th>
                                <th style={{ fontSize: 14 }}>Operator Username</th>
                                <th style={{ fontSize: 14 }}>Email</th>
                                <th style={{ fontSize: 14 }}>Last Synced</th>
                                <th style={{ fontSize: 14 }}>Last Download</th>
                                <th style={{ fontSize: 14 }}>Download %</th>
                                {/* <th style={{ fontSize: 14 }}>Upload %</th> */}
                                <th style={{ fontSize: 14 }}>First Login</th>
                                <th style={{ fontSize: 14 }}>Last Login</th>
                                {/* <th style={{ fontSize: 14 }}>Last Logout</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 ? (
                                data.map((item: any, index: number) => (
                                    <tr key={item?._id}>
                                        <td style={{ fontSize: 12 }}>{index + 1}</td>
                                        <td style={{ fontSize: 12 }}>{item?.centerId?.[0]}</td>
                                        <td style={{ fontSize: 12 }}>{item?.userName}</td>
                                        <td style={{ fontSize: 12 }}>{item?.operatorId}</td>
                                        <td style={{ fontSize: 12 }}>{item?.operatorName}</td>
                                        <td style={{ fontSize: 12 }}>{item?.emailId}</td>
                                        {/* <td>{item?.examId}</td>
                                    <td>{item?.shiftId}</td> */}
                                        <td style={{ fontSize: 12 }}>
                                            {moment(item?.lastSyncedOn).isValid()
                                                ? moment(item?.lastSyncedOn).format("DD-MM-YYYY hh:mm A")
                                                : "N/A"}
                                        </td>
                                        <td style={{ fontSize: 12 }}>
                                            {moment(item?.lastDownloadAt).isValid()
                                                ? moment(item?.lastDownloadAt).format("DD-MM-YYYY hh:mm A")
                                                : "N/A"}
                                        </td>
                                        <td style={{ fontSize: 12 }}>
                                            {item?.meta?.downloadStats?.percentage >= 0
                                                ?
                                                <div className="text-nowrap">
                                                    <span>{item?.meta?.downloadStats?.percentage}%</span><br />
                                                    <span className="fs-12">Synced on: <br /> {moment(item?.meta?.downloadStats?.syncedOn).format("DD-MM-YYYY hh:mm A")}</span>
                                                </div>
                                                : "N/A"}
                                        </td>
                                        {/* <td style={{ fontSize: 12 }}>
                                            {item?.meta?.uploadStats.percentage >= 0
                                                ?
                                                <div className="text-nowrap">
                                                    <span>{item?.meta?.uploadStats?.percentage}%</span><br />
                                                    <span className="fs-12">Synced on: <br /> {moment(item?.meta?.uploadStats?.syncedOn).format("DD-MM-YYYY hh:mm A")}</span>
                                                </div>
                                                : "N/A"}
                                        </td> */}
                                        <td style={{ fontSize: 12 }}>
                                            {
                                                item?.meta?.loginTimes?.length > 0
                                                    ? moment(item?.meta?.loginTimes?.[0]).format("DD-MM-YYYY hh:mm A")
                                                    : "N/A"}
                                        </td>
                                        <td style={{ fontSize: 12 }}>
                                            {
                                                item?.meta?.loginTimes?.length > 0
                                                    ? moment(item?.meta?.loginTimes?.[item?.meta?.loginTimes?.length - 1]).format("DD-MM-YYYY hh:mm A")
                                                    : "N/A"}
                                        </td>
                                        {/* <td style={{ fontSize: 12 }}>
                                            {
                                                item?.meta?.logoutTimes?.length > 0
                                                    ? moment(item?.meta?.logoutTimes?.[item?.meta?.logoutTimes?.length - 1]).format("DD-MM-YYYY hh:mm A")
                                                    : "N/A"}
                                        </td> */}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={12} className="text-center">
                                        No Data Available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

export default DataTable;
