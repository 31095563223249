import { useState } from "react";
import { Button, Card } from "react-bootstrap";

interface Props {
    headers: string[];
    createCsv: () => Promise<any[]>;
    fileName?: string
}

const DownloadCsv = ({ headers, createCsv, fileName="export" }: Props) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleCreateCsv = async () => {
        try {
            setLoading(true);
            setError(null);
            const result = await createCsv();
            setData(result);
        } catch (err) {
            setError('Failed to create CSV data');
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadCsv = () => {
        if (!data.length) return;

        try {
            const csvContent = [
                headers.join(','),
                ...data.map(row =>
                    headers.map(header => {
                        const value = row[header];
                        const escaped = String(value).replace(/"/g, '""');
                        return `"${escaped}"`;
                    }).join(',')
                )
            ].join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download',  fileName + '.csv');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            setError('Failed to download CSV');
            console.error('Error downloading CSV:', err);
        }
    };

    return (
        <>
            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <div className="d-flex justify-content-end gap-2">
                        <Button
                            onClick={handleCreateCsv}
                            disabled={loading}
                        >
                            {loading ? 'Creating...' : 'Create CSV'}
                        </Button>

                        <Button
                            onClick={handleDownloadCsv}
                            disabled={!data?.length || loading}
                        >
                            Download CSV
                        </Button>
                    </div>

                    {error && (
                        <div className="text-danger">
                            {error}
                        </div>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};

export default DownloadCsv;