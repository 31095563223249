import React, { useEffect, useState } from 'react'
import { Badge, Button, Image, Modal, Table } from 'react-bootstrap'
import { VerificationProcessEnum } from '../enum/VerficationProcess.enum'
import { ClientService } from '../services/client.service'
import moment from 'moment'
import PreviewModal from './Preview.modal'
import toast from 'react-hot-toast'
import SplitPreviewModal from './SplitPreview.modal'
import ManualVerificationModal from './ManualVerification.modal'
import { FaEye } from 'react-icons/fa6'

interface IUserModal {
    show: any,
    handleClose: any
    shiftId: any
}

export default function UserModal(props: IUserModal) {

    const [userData, setUserData] = useState<any>();
    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)
    const [verificationEnums, setVerificationEnums] = useState<any>();
    const [splitPreviewData, setSplitPreviewData] = useState<any>(null);
    const [manualVerificationData, setManualVerificationData] = useState<any>({});

    const getUserDataByShiftId = async () => {
        await ClientService.getDatabyUserId(props.shiftId, props.show).then((res) => {
            if (res.status === 200) {
                if (res.status === 200) {
                    setUserData(res.data)
                }
            }
        })
    }


    const getVerificationEnums = async () => {
        await ClientService.getVerificationEnums().then(res => {
            if (res.status === 200) {
                setVerificationEnums(Object.values(res.data.enums).filter((data: any) => data !== "gps").map((data: any) => {
                    return {
                        label: data === 'fingerprint' ? "Right Fingerprint" : data === "fingerprint2" ? "Left Fingerprint" : data,
                        value: data
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    useEffect(() => {
        if (props.show) {
            getUserDataByShiftId();
            getVerificationEnums();
        }
    }, [props.shiftId, props.show]);

    return (
        <>
            <Modal
                show={props.show ? true : false}
                onHide={props.handleClose}
                size="lg"
                animation={true}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <div>
                        <h5>
                            User Details
                        </h5>
                        <div className="d-flex align-items-center mb-3">
                            {/* <div style={{ width: 100, height: 100 }}>
                                <Image src={userData?.user?.avatar} width={"100%"} height={"100%"} style={{ borderRadius: "50%" }} onClick={() => setShowPreviewModal(userData?.user?.avatar)} />
                            </div> */}
                            {userData?.user?.avatar ? (
                                <div style={{ width: 100, height: 100 }} onClick={() => setShowPreviewModal(userData?.user?.avatar)}>
                                    <Image src={userData?.user?.avatar} width="100%" height="100%" style={{ borderRadius: '50%' }} />
                                </div>
                            ) : (
                                <span>No Image</span>
                            )}
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Name:</p>
                            <p className="flex-grow-1">{userData?.user?.name || "--"}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Roll No.:</p>
                            <p className="flex-grow-1">{userData?.user?.rollNo || "--"}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Gender:</p>
                            <p className="flex-grow-1">{userData?.user?.gender || "--"}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>City:</p>
                            <p className="flex-grow-1">{userData?.user?.city || "--"}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>State:</p>
                            <p className="flex-grow-1">{userData?.user?.state || "--"}</p>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5 className="mb-2">
                            Exam Details
                        </h5>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Exam Name:</p>
                            <p className="flex-grow-1">{userData?.exam?.name}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Live:</p>
                            <p className="flex-grow-1">{userData?.exam?.isLive ? <span className="fw-bold text-success">Live</span> : <span className="fw-bold text-danger">Not Live</span>}</p>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5 className="mb-2">
                            Shift Details
                        </h5>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Shift Name:</p>
                            <p className="flex-grow-1">{userData?.shift?.shiftName}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Start Time:</p>
                            <p className="flex-grow-1">{moment(userData?.shift?.startTime).format("DD-MM-YYYY hh:mm a")}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>End Time:</p>
                            <p className="flex-grow-1">{moment(userData?.shift?.endTime).format("DD-MM-YYYY hh:mm a")}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="mt-2">
                        <h5>Verifications</h5>
                        <h5>
                            Verifications
                        </h5>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Center Id:</p>
                            <p className="flex-grow-1">{userData?.verifications?.centerId}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Biometric Operator:</p>
                            <p className="flex-grow-1">{userData?.verifications?.invigilatorId?.userName}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Live Photo:</p>
                            <div style={{ width: 70, height: 70 }}>
                                <Image src={userData?.verifications?.userLivePhoto} width={"100%"} height={"100%"} style={{ borderRadius: "50%" }} onClick={() => setSplitPreviewData({ uploadPhoto: userData?.user?.avatar, livePhoto: userData?.verifications?.userLivePhoto })} />
                            </div>
                        </div>
                        <div>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Verification Method</th>
                                        <th>Success</th>
                                        <th>Manually Verification</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userData?.verifications && userData?.verifications?.verificationDetails?.filter((data: any) => data?.method !== "gps").map((data: any, index: number) => {
                                        return (
                                            <tr>
                                                <td className='text-align-center'>{index + 1}</td>
                                                <td className='text-align-center'>
                                                    {data?.method === "fingerprint" ? "Right Fingerprint" : data?.method === "fingerprint2" ? "Left Fingerprint" : data?.method}
                                                </td>
                                                <td>
                                                    <Badge bg={data?.success ? "success" : "danger"}>{data?.success ? "Success" : "Failed"}</Badge>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <Badge bg={data?.manuallyVerified ? "warning" : "success"}>{data?.manuallyVerified ? "Yes" : "No"}</Badge>
                                                        {
                                                            data?.manuallyVerified &&
                                                            <FaEye style={{ cursor: "pointer" }} onClick={() => setManualVerificationData(data)} />
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >

            <PreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(undefined)}
            />

            <SplitPreviewModal
                show={splitPreviewData}
                handleClose={() => setSplitPreviewData(null)}
            />

            <ManualVerificationModal
                data={manualVerificationData}
                handleClose={() => setManualVerificationData(null)}
            />
        </>
    )
}
