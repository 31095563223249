const url = {
    login: "/client/clientLogin",
    getAllShifts: "/client/getAllExamShift",
    getVerificationEnums: "/client/getVerificationEnums",


    stats: {
        getCountInfo: "/client/countInfo",
        getInvigilatorStats: "/client/stats",
        getVerifiedCount: "/client/verifiedCountUsers",
        getVerificationCountStats:"/client/getVerificationCountStats",
        getVerificationByState:"/client/getVerificationByState",
        // getVerifiedUserCount: "client/verifiedCountUsers",
    },

    client: {
        getVerificationData: "/client/getVerification",
        getUniqueCity: "/client/uniqueCities",
        getUniqueState: "/client/uniqueStates",
        getUniquesZone: "/client/uniqueZones",
        getUniqueCenter: "/client/uniqueCenters",
        getUsers: "/client/getUsers",
        getUserData: "/client/getUsersData",
        downloadVerifiedZip: "/client/verifiedZip",
        searchByRollNo: "/client/user/search",
        getDatabyUserId: "/client/getDatabyUserId",
        getCenterCount: "/client/centerCount",
        getCenterStats: "/client/centerStats",
        centerStatsDetails:"/client/centerStatsDetails",
        getAllCSRReport: "/client/CSRReports",
        getAllBypassData: "/client/user/bypass",
        getDropdownData: "/client/dropdown",
        getInvigilators: "/client/invigilators",
        getStatsByStateData:"/client/dashboard/stats/state",
        getCityStatsTime:"/client/dashboard/stats/time/city",
        getStatsByCityData:"/client/dashboard/stats/city",

        getVerificationTrends: "/client/stats/verification/trends",
        getCityDetails:"/client/dashboard/stats/time/center",
    },

    dropdown:{
        getZones:'/client/dropdown/zones',
        getStates:'/client/dropdown/states',
        getCities:'/client/dropdown/district',
        getCenters:'/client/dropdown/center',
       
    }

};

export default url;
