import React from 'react'
import { Button, Image, Modal } from 'react-bootstrap';

interface IPreviewModal {
    show: any,
    handleClose: any
}

export default function SplitPreviewModal(props: IPreviewModal) {
    return (
        <Modal
            show={props.show ? true : false}
            onHide={props.handleClose}
            size="lg"
            animation={true}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center">
                    <div className="text-center mx-2">
                        <div style={{ width: 300, height: 300 }}>
                            <Image
                                className="p-2"
                                src={props.show?.uploadPhoto}
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'contain', border: '1px solid #ccc', borderRadius: 5 }}
                            />
                        </div>
                        <p className="mt-2 fw-bold">Uploaded Photo</p>
                    </div>
                    <div className="text-center mx-2">
                        <div style={{ width: 300, height: 300 }}>
                            <Image
                                className="p-2"
                                src={props.show?.livePhoto}
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'contain', border: '1px solid #ccc', borderRadius: 5 }}
                            />
                        </div>
                        <p className="mt-2 fw-bold">Live Image</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    )
}
